<template>
  <div class="auth-wrapper auth-v1 px-2 bg">
    <div class="auth-inner py-2">
      <div class="text-center mb-2">
        <img
          src="/img/logo.png"
          alt="logo"
          height="70"
        >
      </div>
      <b-card class="mb-0">
        <div class="mt-2 mb-1">
          <b-card-text style="padding: 0 15px 0 15px">
            <div>
              <validation-observer ref="loginForm">
                <b-form
                  class="auth-login-form"
                  @submit="getOTPs"
                >
                  <!-- email -->
                  <div v-if="!ref">
                    <b-form-group>
                      <label for="tel">เบอร์โทรศัพท์</label>
                      <validation-provider
                        #default="{ errors }"
                        name="tel"
                        rules="required|min:10"
                      >
                        <b-form-input
                          v-model="tel"
                          name="tel"
                          type="number"
                          placeholder="กรุณากรอกเบอร์โทรศัพท์"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          :state="errors.length > 0 ? false : null"
                          maxlength="10"
                          @keydown.enter.prevent="getOTPs"
                        />
                        <small class="text-danger">{{
                          errors[0] ? "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก" : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button
                      class="btn-theme-two btn2 btn-block cg"
                      variant="primary"
                      @click="getOTPs"
                    >
                      ยืนยัน
                    </b-button>
                  </div>

                  <div v-if="ref && !match">
                    <div class="row">
                      <div class="col-4">
                        <div>
                          <label>&nbsp;</label><br>
                          <vue-captcha
                            ref="captcha"
                            :captcha.sync="code"
                            font-weight="bold"
                            @on-change="handleChange"
                          />
                        </div>
                      </div>
                      <div class="col-8">
                        <b-form-group>
                          <label
                            for="password"
                          >รหัส Captcha
                            <small
                              class="text-danger"
                            >ref : {{ ref }}</small></label>
                          <validation-provider
                            #default="{ errors }"
                            name="otp"
                          >
                            <b-form-input
                              id="otp"
                              v-model="otp"
                              name="otp"
                              type="otp"
                              placeholder="กรุณากรอกตัวอักษรที่แสดง"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              :state="errors.length > 0 ? false : null"
                              maxlength="4"
                              @keydown.enter.prevent="checkOTP"
                            />
                            <small class="text-danger">{{
                              errors[0] ? "กรุณากรอกรหัส OTP 6 หลัก" : ""
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>

                    <b-button
                      class="btn-theme-two cg"
                      block
                      style="margin-top: 23.5px"
                      variant="primary"
                      @click="checkOTP"
                    >
                      ยืนยัน
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>

            <div v-if="!next && match">
              <validation-observer ref="loginForm">
                <b-form class="auth-login-form">
                  <!-- email -->
                  <b-form-group>
                    <label
                      for="password"
                    >ตั้งรหัส พาสเวิร์ด เข้าสู่ระบบ</label>
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|min:6"
                    >
                      <b-form-input
                        v-model="password"
                        name="password"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                        @keydown.enter.prevent="Next"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "กรุณาตั้งรหัสผ่าน 6 ตัวขึ้นไป" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <label for="invited_by">รหัสแนะนำ</label>
                    <b-form-input
                      v-model="invited_by"
                      name="invited_by"
                      type="text"
                      @keydown.enter.prevent="Next"
                    />
                  </b-form-group>

                  <b-button
                    class="btn-theme-two cg"
                    block
                    style="margin-top: 23.5px"
                    variant="primary"
                    @click="Next"
                  >
                    ถัดไป
                  </b-button>
                </b-form>
              </validation-observer>
            </div>

            <div v-if="next && match">
              <!-- <div> -->
              <validation-observer ref="loginForm">
                <b-form class="auth-login-form">
                  <!-- email -->
                  <b-form-group>
                    <label for="name">ชื่อ</label>
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="name"
                        name="name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "กรุณากรอกชื่อให้ถูกต้อง" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <label for="surname">นามสกุล</label>
                    <validation-provider
                      #default="{ errors }"
                      name="surname"
                      rules="required"
                    >
                      <b-form-input
                        v-model="surname"
                        name="surname"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "กรุณากรอกนามสกุลให้ถูกต้อง" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div>
                    <small class="text-danger">
                      *กรอกชื่อ-นามสกุล ให้ตรงกับชื่อบัญชี หรือทรูมันนี่วอลเล็ท
                    </small>
                  </div>
                  <!-- <b-form-checkbox
                        v-model="wallet"
                        checked="fasle"
                        name="check-button"
                        switch
                        inline
                        class="custom-control-primary mb-1"
                      >
                        ทรูมันนี่วอลเล็ท
                      </b-form-checkbox> -->
                  <b-form-group
                    class="mb-1"
                  >
                    <label class="fg">กรอกหมายเลขทรูมันนี่วอลเล็ท</label>
                    <b-form-input
                      v-model="trueaccno"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="10"
                      placeholder="กรุณากรอกหมายเลขทรูมันนี่วอลเล็ท"
                      type="number"
                    />
                  </b-form-group>

                  <!-- select bank -->
                  <b-form-group>
                    <label for="bank">เลือกธนาคาร</label>
                    <div
                      class="select_bank"
                      :style="`border: 2px solid ${
                        selected ? selected.bg : '#1e1e1e'
                      };`"
                      @click="showModal"
                    >
                      <img
                        :src="`/bankIcon/${
                          selected ? selected.path_photo : 'bk.png'
                        }`"
                        :style="`background-color: ${
                          selected ? selected.bg : '#FF6700'
                        };`"
                        class="bank_icon"
                      >
                      <span>{{
                        selected ? selected.name : "กรุณาเลือกธนาคาร"
                      }}</span>
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <label for="accno">เลขที่บัญชี</label>
                    <validation-provider
                      #default="{ errors }"
                      name="accno"
                      rules="required|min:10"
                    >
                      <b-form-input
                        v-model="accno"
                        name="accno"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="13"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "กรุณากรอกเลขที่บัญชีให้ถูกต้อง" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- select bank -->
                  <b-form-group>
                    <label
                      for="knowby"
                    >รู้จักเราช่องทางใด
                      <small
                        class="text-warning"
                      >(รับสิทธิพิเศษ VIP)</small></label>
                    <b-form-select
                      v-model="knowby"
                      :options="options"
                      name="knowby"
                      required
                    />
                  </b-form-group>

                  <b-form-group>
                    <label
                      for="lineID"
                    >ไอดีไลน์
                      <small
                        class="text-warning"
                      >(รับสิทธิพิเศษ VIP)</small></label>
                    <b-form-input
                      v-model="lineID"
                      name="lineID"
                      type="text"
                      @keydown.enter.prevent="validationForm"
                    />
                  </b-form-group>

                  <b-button
                    class="btn-theme-two cg"
                    block
                    style="margin-top: 23.5px"
                    variant="primary"
                    @click="validationForm"
                  >
                    ยืนยันข้อมูล
                  </b-button>
                </b-form>
              </validation-observer>
            </div>
          </b-card-text>

          <b-modal
            ref="my-modal"
            centered
            title="Vertically Centered"
            hide-footer
            hide-header
            hide-header-close
          >
            <div class="row">
              <div
                v-for="option in bank"
                :key="option.bankcode"
                class="col-4 mb-2 mt-1 text-center"
                @click="select(option)"
              >
                <b-avatar
                  size="80"
                  :src="`/bankIcon/${option.path_photo}`"
                  :style="`background-color: ${option.bg}; padding: 10px; border: 4px solid #1e110d66; border-radius: 0;`"
                />
                <p style="color: #000">
                  {{ option.codename }}
                </p>
              </div>
            </div>
          </b-modal>

          <div class="divider text-secondary">
            หากท่านมีบัญชีอยู่แล้ว <router-link
              :to="{ name: 'home'}"
            >
              <button class="btn-login">
                คลิ๊ก เข้าสู่ระบบ
              </button>
            </router-link>
          </div>
        </div>
      </b-card>
      <b-card-text class="text-center mt-2 text-white">
        <span>Copyright © 2022 supported by <a
          href="/"
          class="text-white"
        >Sabaidee Lotto</a></span><br>
        <small>เวอร์ชั่น 1.0.2</small>
      </b-card-text>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BFormSelect,
  BModal,
  BAvatar,
  BCard,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import VueCaptcha from 'vue-captcha-code'

export default {
  components: {
    // BSV
    VueCaptcha,
    BCard,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BModal,
    BAvatar,
    // BFormCheckbox,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      code: '',
      getOTP: false,
      confirmPassword: false,
      lineID: '',
      otp: '',
      knowby: null,
      password: '',
      promo: '',
      wallet: '',
      promotion: '',
      surname: '',
      name: '',
      s_bank: null,
      accno: '',
      selected: null,
      invite_friend: '',
      fname: '',
      lname: '',
      trueaccno: '',
      bank: '',
      options: [
        { value: null, text: 'คุณรู้จักเราผ่านช่องทางใด' },
        { value: 1, text: 'Facebook' },
        { value: 2, text: 'Youtube' },
        { value: 3, text: 'Tik Tok' },
        { value: 4, text: 'Google' },
        { value: 5, text: 'SMS' },
        { value: 6, text: 'เพื่อนแนะนำ' },
      ],
      username: '',
      tel: '',
      status: '',
      // validation rules
      required,
      email,
      ag: 1,
      ref: null,
      match: null,
      next: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getBank()
    if (this.$route.params.invcode) {
      // console.log(this.$route.params.invcode)
      this.knowby = 6
      this.invited_by = this.$route.params.invcode
    }
    if (this.$route.params.ag) {
      // console.log(this.$route.params.ag)
      this.ag = this.$route.params.ag
    } else {
      this.ag = 1
    }
    if (this.$route.params.noinv) {
      // console.log(this.$route.params.noinv)
      this.knowby = 6
      this.noinv = this.$route.params.noinv
    }
    if (localStorage.getItem('invcode')) {
      this.knowby = 6
      this.invited_by = localStorage.getItem('invcode')
    }
    if (this.$route.query.ref) {
      this.knowby = 6
      this.invited_by = this.$route.query.ref
    }
    if (this.$route.query.type) {
      // 1 = Sub Agent , 2 = ลูกค้า
      this.invited_type = this.$route.query.type
    }
    if (this.$route.query.register) {
      this.$router.push('/login')
    }
    // console.log(this.$route.query.ref)
  },
  methods: {
    loginLink() {
      if (this.$route.query.ref) {
        this.$router.push({ name: 'login', query: { ref: this.$route.query.ref } })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    Next() {
      if (this.password) {
        this.next = true
      }
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          if (this.promo === false) {
            this.promotion = null
          }
          // if (this.wallet === true) {
          //   this.s_bank = 'truewallet'
          //   this.accno = null
          // } else {
          //   this.trueaccno = null
          // }
          // console.log(this.ag)
          const formData = {
            // username: `HIT${this.tel}`,npm install
            usertype: this.invited_type,
            agent_id: this.ag,
            name: this.name,
            surname: this.surname,
            invited_by: this.$route.query.ref,
            tel: this.tel,
            bank: this.s_bank,
            acc_no: this.accno,
            password: this.password,
            knowby: this.knowby,
            main_promotion: this.promotion,
            trueaccno: this.trueaccno,
          }
          // console.log(formData)
          this.$http
            .post('https://api.sabaideelotto.com/api/users/store', formData)
            .then(() => {
              // console.log(res)
              useJwt
                .login({
                  email: this.tel,
                  password: this.password,
                  agent_id: this.ag,
                  Detail: {
                    Ip: this.Ip,
                    UserAgent: navigator.userAgent,
                    Platform: navigator.platform,
                  },
                })
                .then(response => {
                  const userData = response.data
                  useJwt.setToken(response.data.token)
                  useJwt.setRefreshToken(response.data.refreshToken)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  localStorage.setItem('CheckClick', true)
                  // this.$router
                  //   .push(getHomeRouteForLoggedInUser(userData.role))
                  if (userData.role === 'admin') {
                    window.location.href = '/'
                  } else if (userData.role === 'user') {
                    window.location.href = '/buy-lottery'
                  } else if (userData.role === 'agent') {
                    window.location.href = '/agent/buy-lottery'
                  }
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    getOTPs() {
      // this.SwalError(
      //   'ปิดรับสมัครชั่วคราว เนื่องจากรอบของการแจกเครดิตฟรีหมดแล้วค่ะ',
      // )
      const formData = {
        tels: this.tel,
        agent_id: this.ag,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/otp/checktel', formData)
        .then(response => {
          this.ref = response.data.ref
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    checkOTP() {
      if (this.code === this.otp) {
        this.match = true
      } else {
        this.$refs.captcha.refreshCaptcha()
        this.SwalError('รหัส Captcha ไม่ถูกต้อง')
      }
      // const formData = {
      //   tels: this.tel,
      //   ref: this.ref,
      //   otps: this.otp,
      //   agent_id: this.ag,
      // }
      // this.$http
      //   .post('https://api.power100th.com/api/otp/check', formData)
      //   .then(response => {
      //     this.match = response.data.match
      //     this.username = response.data.username
      //     // this.Success('ยืนยัน OTP สำเร็จ')
      //     this.confirmOTP()
      //   })
      //   .catch(error => {
      //     this.SwalError(error.response.data.message)
      //   })
    },
    select(option) {
      this.$refs['my-modal'].hide()
      this.selected = option
      this.s_bank = option.bankcode
    },
    getBank() {
      this.$http
        .get('https://api.sabaideelotto.com/api/notlogin/bank/list')
        .then(response => {
          // console.log(response.data)
          this.bank = response.data
        })
        .catch(error => {
          console.log(error.response.data.message)
        })
    },
    sendOTP() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">ส่ง OTP สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    confirmOTP() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">ยืนยันรหัส OTP สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    SwalError(message) {
      this.$swal({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },
}
</script>

<style scoped>
.card-body {
  padding: 0 !important;
}
.bg {
    background: rgb(5,219,183);
background: radial-gradient(circle, rgb(0, 156, 130) 0%, rgba(0,60,50,1) 100%);
}

.btn-login {
  background: rgb(236, 183, 9);
  border-radius: 25px;
  padding: 2px 10px;
  border: none;
  box-shadow: 0 10px 5px 0 rgba(31, 83, 1, 0.2);
}

</style>

  <style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
